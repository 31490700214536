.numbrix {
	--m-numbrix-number-of-cells: 9;
	--m-numbrix-cell-background-color: #808080;
	--m-numbrix-cell-prefilled-background-color: #92b8ef;
	--m-numbrix-cell-solved-background-color: #fffc00;
	--m-numbrix-primary-button-hover-color: #146ca2;
	--font-family: var(--typography-typeface-secondary-family);

	font-family: var(--font-family);
}

/* Numbrix Board */
.numbrixBoard {
	--background-color: #99b8eb;

	align-items: center;
	aspect-ratio: 1 / 1;
	background-color: var(--background-color);
	display: flex;
	justify-content: center;
	justify-self: center;
	position: relative;
	width: 100%;
}

.numbrixGameBoard {
	height: 100%;
	width: 100%;

	&:not([hidden]) {
		display: grid;
		grid-template-columns: repeat(var(--m-numbrix-number-of-cells), 1fr);
		grid-template-rows: repeat(var(--m-numbrix-number-of-cells), 1fr);
	}
}

.numbrixBoardMsg {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-s);
	position: relative;
	text-align: center;
}

.numbrixBoardMsgTitle {
	--font-family: var(--typography-h-xl-family);
	--font-size: var(--typography-h-xl-size);
	--font-weight: var(--typography-h-xl-weight);
	--line-height: var(--typography-h-xl-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
}

.numbrixBoardMsgText {
	--font-family: var(--typography-body-desktop-family);
	--font-size: var(--typography-body-desktop-size);
	--font-weight: var(--typography-body-desktop-weight);
	--line-height: var(--typography-body-desktop-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);

	&:not(:last-child) {
		margin-bottom: var(--spacing-m);
	}
}

.numbrixBoardMsgNote {
	--font-family: var(--typography-body-s-family);
	--font-size: var(--typography-body-s-size);
	--font-weight: var(--typography-body-s-weight);
	--line-height: var(--typography-body-s-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
}

/* Numbrix Cell */
.numbrixCell {
	--background-color: #cbced5;

	background-color: var(--background-color);
	border: 1px solid var(--m-numbrix-cell-background-color);
	border-right: none;
	border-top: none;
	margin: 0;
	padding: 0;
	text-align: center;
	vertical-align: top;
}

.numbrixCellPrefilled {
	background-color: var(--m-numbrix-cell-prefilled-background-color);
}

.numbrixCellSolved {
	background-color: var(--m-numbrix-cell-solved-background-color);
}

.numbrixCellActive {
	background: var(--m-numbrix-cell-solved-background-color);
}

.numbrixCellTop {
	border-top: 1px solid var(--m-numbrix-cell-background-color);
}

.numbrixCellBottom {
	border-bottom: 1px solid var(--m-numbrix-cell-background-color);
}

.numbrixCellLeft {
	border-left: 1px solid var(--m-numbrix-cell-background-color);
}

.numbrixCellRight {
	border-right: 1px solid var(--m-numbrix-cell-background-color);
}

.numbrixCellInput {
	--color: var(--color-neutral-black);
	--font-family: var(--typography-body-s-family);
	--font-size: var(--typography-h-m-size);
	--font-weight: var(--typography-h-m-weight);
	--line-height: var(--typography-h-m-line-height);

	background: none;
	border: none;
	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	height: 100%;
	line-height: var(--line-height);
	padding: 0;
	position: relative;
	text-align: center;
	width: 100%;

	&:focus {
		z-index: 1;
	}
}

/* Numbrix Controls */
.numbrixControls {
	--background-color: var(--color-neutral-white);

	align-items: center;
	background-color: var(--background-color);
	border-color: var(--color-neutral-dark-20);
	border-radius: 0 0 var(--radius-l) var(--radius-l);
	border-style: solid;
	border-width: 0 1px 1px 1px;
	display: flex;
	gap: var(--spacing-4xs);
	padding: var(--spacing-xs) var(--spacing-l);

	@media (--bp-large) {
		padding-bottom: var(--spacing-l);
	}
}

/* Numbrix Timer */
.numbrixTimer {
	font-size: var(--typography-h-2xs-desktop-size);
	font-weight: var(--typography-h-2xs-desktop-weight);
	margin: 0 auto;
}

/* Numbrix Button */
.numbrixButton {
	--background-color: var(--color-neutral-dark-5);
	--color: var(--color-neutral-black);

	align-items: center;
	background-color: var(--background-color);
	border: none;
	border-radius: 50%;
	color: var(--color);
	cursor: pointer;
	display: flex;
	height: 40px;
	justify-content: center;
	transition: color var(--transition-speed-fast) ease;
	width: 40px;

	& svg {
		height: 20px;
		width: 20px;
	}

	&:hover {
		--color: var(--m-numbrix-primary-button-hover-color);
	}
}

.numbrixButton:disabled,
.numbrixButton:hover:disabled,
.numbrixButtonWhite:disabled,
.numbrixButtonWhite:hover:disabled {
	--color: var(--color-neutral-dark-20);

	cursor: not-allowed;
}

.numbrixButtonWhite {
	--background-color: var(--color-neutral-white);
	--color: var(--color-neutral-black);

	align-items: center;
	background-color: var(--background-color);
	border: none;
	box-shadow: 0 17px 36px 0 #0000001a;
	color: var(--color);
	cursor: pointer;
	display: flex;
	font-size: var(--typography-body-s-size);
	font-weight: 600;
	gap: var(--spacing-3xs);
	padding: var(--spacing-xs) var(--spacing-2xl);
	transition: box-shadow var(--transition-speed-normal) ease;

	& svg {
		height: 14px;
		width: 14px;
	}

	&:hover {
		box-shadow: none;
		text-decoration: underline;
	}
}

/* Numbrix Footer */
.numbrixFooter {
	margin-top: var(--spacing-xl);

	@media (--bp-large) {
		margin-top: var(--spacing-xl-desktop);
	}
}

.numbrixFooterPanel:not(:last-child) {
	margin-bottom: var(--spacing-xl);

	@media (--bp-large) {
		margin-bottom: var(--spacing-xl-desktop);
	}
}

.numbrixFooterTitle {
	--font-size: var(--typography-h-m-size);
	--font-weight: var(--typography-typeface-secondary-weight-bold);

	margin-bottom: var(--spacing-xl);

	@media (--bp-large) {
		margin-bottom: var(--spacing-xl-desktop);
	}
}

.numbrixFooterText {
	--font-size: var(--typography-body-desktop-size);

	&:not(:last-child) {
		margin-bottom: var(--spacing-m);
	}
}
