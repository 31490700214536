.breadcrumbsContainer {
	--color: var(--breadcrumbs-color-default);
	--vertical-spacing: var(--breadcrumbs-vertical-spacing);

	color: var(--color);
	display: block;
	margin-block: var(--vertical-spacing);
	text-transform: uppercase;
}

.breadcrumbsList {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	list-style: none;
	margin: 0;
	padding: 0;
}

.breadcrumbsItem {
	align-items: center;
	display: flex;
	gap: 0.25rem;
	line-height: 1;
}

.breadcrumbsItemLink {
	--color: var(--breadcrumbs-color-link);
	--color-active: var(--breadcrumbs-color-link-active);
	--color-focus: var(--breadcrumbs-color-link-focus);
	--color-hover: var(--breadcrumbs-color-link-hover);
	--font-family: var(--breadcrumbs-typography-link-family);
	--font-size: var(--breadcrumbs-typography-link-size);
	--font-weight: var(--breadcrumbs-typography-link-weight);
	--letter-spacing: var(--breadcrumbs-typography-link-letter-spacing);
	--line-height: var(--breadcrumbs-typography-link-line-height);
	--outline-color: var(--breadcrumbs-color-link-focus);

	display: block;
	max-width: 9.375rem;
	overflow: hidden;
	padding-bottom: 0.125rem; /* Added to prevent text underline on hover from being cut off */
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:active {
		color: var(--color-active);
	}

	&:focus {
		color: var(--color-focus);
	}

	&:hover {
		color: var(--color-hover);
		text-decoration: underline;
	}

	@media (--bp-large) {
		--font-family: var(--breadcrumbs-typography-link-desktop-family);
		--font-size: var(--breadcrumbs-typography-link-desktop-size);
		--font-weight: var(--breadcrumbs-typography-link-desktop-weight);
		--letter-spacing: var(--breadcrumbs-typography-link-desktop-letter-spacing);
		--line-height: var(--breadcrumbs-typography-link-desktop-line-height);

		max-width: 12.5rem;
	}
}

@container style(--is-dark-mode: 1) {

	.breadcrumbsItemLink {
		--color: var(--color-neutral-white);
		--color-hover: var(--color-neutral-white);
		--color-active: var(--color-neutral-white);
		--color-focus: var(--color-neutral-white);
	}
}

/* Removes top margin from .breadcrumbsItem inside .content */
:global(.content) .breadcrumbsItem {
	margin-top: 0;
}
