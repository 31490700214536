.quote {

	/* Border */
	--width-border: 1px;
	--style-border: solid;
	--color-border: var(--color-neutral-dark-40);

	/* Layout */
	--aspect-ratio: unset;
	--block-size-padding: var(--spacing-l);
	--inline-size-gap: var(--spacing-l);
	--inline-size-media: 9rem;

	border-block: var(--width-border) var(--style-border) var(--color-border);
	padding-block: var(--block-size-padding);

	& .quote__text {

		/* Typography */
		--font-size: var(--quote-typography-title-size);
		--font-weight: var(--quote-typography-title-weight);
		--line-height: var(--quote-typography-title-line-height);
		--letter-spacing: var(--quote-typography-title-letter-spacing);
		--font-family: var(--quote-typography-title-family);

		@media (--bp-large) {

			--font-size: var(--quote-typography-title-desktop-size);
			--font-weight: var(--quote-typography-title-desktop-weight);
			--line-height: var(--quote-typography-title-desktop-line-height);
			--letter-spacing: var(--quote-typography-title-desktop-letter-spacing);
			--font-family: var(--quote-typography-title-desktop-family);
		}

		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
	}

	&.is-large-size .quote__text {

		--font-size: var(--quote-typography-title-desktop-xl-size);
		--font-weight: var(--quote-typography-title-desktop-xl-weight);
		--line-height: var(--quote-typography-title-desktop-xl-line-height);
		--letter-spacing: var(--quote-typography-title-desktop-xl-letter-spacing);
		--font-family: var(--quote-typography-title-desktop-xl-family);
	}

	&.is-extra-large-size .quote__text {

		--font-size: var(--quote-typography-title-desktop-2xl-size);
		--font-weight: var(--quote-typography-title-desktop-2xl-weight);
		--line-height: var(--quote-typography-title-desktop-2xl-line-height);
		--letter-spacing: var(--quote-typography-title-desktop-2xl-letter-spacing);
		--font-family: var(--quote-typography-title-desktop-2xl-family);
	}

	--text-alig-image-attribution: end;

	&.is-aspect-ratio-1-1 {
		--aspect-ratio: 1/1;
	}

	&.is-aspect-ratio-4-5 {
		--aspect-ratio: 4/5;
	}

	@media (--bp-large) {
		--inline-size-media: 13rem;
	}

	/* Attribution Photo Layout */
	&.is-attribution-photo {

		@media (--bp-large) {
			align-items: center;
			display: grid;
			gap: var(--inline-size-gap);
			grid-template-columns: 1fr var(--inline-size-media);
		}
	}

	&.is-full-bleed {
		--inline-size-media: 100%;
		--text-alig-image-attribution: start;

		@media (--bp-large) {
			--inline-size-media: 100%;
			align-items: center;
			display: grid;
			gap: var(--inline-size-gap);
			grid-template-areas: "image quote";
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		& .quote__quoteContainer {
			grid-area: quote;
		}

		& .quote__image {
			grid-area: image;
			margin-block-start: var(--spacing-l);

			@media (--bp-large) {
				margin-block: 0;
			}
		}
	}
}

.quote__contributor-name {
	font-family: var(--quote-typography-contributor-name-family);
	font-size: var(--quote-typography-contributor-name-size);
	font-weight: var(--quote-typography-contributor-name-weight);
	letter-spacing: var(--quote-typography-contributor-name-letter-spacing);
	line-height: var(--quote-typography-contributor-name-line-height);
}

.quote__contributor-role,
.quote__media-attributes {
	font-family: var(--quote-typography-contributor-role-family);
	font-size: var(--quote-typography-contributor-role-size);
	font-weight: var(--quote-typography-contributor-role-weight);
	letter-spacing: var(--quote-typography-contributor-role-letter-spacing);
	line-height: var(--quote-typography-contributor-role-line-height);
}

.quote__quoteContainer {
	margin: 0;
}

.quote__quote {
	margin: 0;

	&::before {
		content: "“";
		font-size: var(--quote-typography-title-size);
		position: relative;
		top: calc(var(--quote-typography-title-size) - 1rem);
	}
}

.quote__quoteAttribution {
	--gap: var(--spacing-l);

	@media (--bp-large) {
		--gap: var(--spacing-l-desktop);

	}

	margin-block-start: var(--gap);
}

.quote__image {
	inline-size: var(--inline-size-media);
	margin: 0;
	margin-inline-start: auto;

	& .quote__imageWrapper {
		aspect-ratio: var(--aspect-ratio);
		position: relative;

		& img {
			height: auto;
			object-fit: cover;
			object-position: center;
		}
	}
}

.quote__media-attributes {
	margin-block-start: var(--spacing-3xs);
	text-align: var(--text-alig-image-attribution);
}
