.mediaCarouselImageWrapper {
	aspect-ratio: var(--ar-1-1);
	margin: 0;
	position: relative;
}

.mediaCarouselImage {
	border-radius: var(--radius-media);
	display: flex;
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.mediaCarouselImageLink {
	display: block;
	height: 100%;
	width: 100%;
}

.mediaCarouselCaption {

	&,
	& * {
		--font-family: var(--typography-body-s-family);
		--font-size: var(--typography-body-s-size);
		--font-weight: var(--typography-body-s-weight);
		--letter-spacing: var(--typography-body-s-letter-spacing);
		--line-height: var(--typography-body-s-line-height);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-top: var(--spacing-2xs);
}

.mediaCarouselImageSource {
	--font-size: var(--typography-body-s-size);

	display: block;
	font-size: var(--font-size);
	margin-top: var(--spacing-2xs);
	opacity: 0.6;

	& a {
		--color: var(--color-neutral-black);

		font-size: var(--font-size);
	}
}
