.pagination {
	--margin-block: var(--pagination-vertical-spacing);

	@media (--bp-medium) {
		--margin-block: var(--pagination-vertical-desktop-spacing);
	}

	align-items: center;
	display: flex;
	gap: var(--spacing-s);
	justify-content: center;
	margin-block: var(--margin-block);
}

.paginationWidget {
	display: flex;
	gap: var(--spacing-3xs);
}

.paginationFlanker {
	display: none;

	@media (--bp-small) {
		display: flex;
	}
}

.paginationEllipsis {
	align-items: center;
	border-radius: 0;
	display: flex;
	height: 3rem;
	justify-content: center;
	padding: 0;
	pointer-events: none;
	width: 3rem;
}

.paginationText {
	text-box: trim-both cap alphabetic;
}

.hideDesktop {

	@media (--bp-medium) {
		display: none;
	}
}

@media ( max-width: 520px ) {

	.paginationWidget a:first-of-type:not(.isCurrent) {
		display: none;
	}
}
