.label {
	--border-radius: var(--radius-label);
	--color: var(--label-color-text, currentColor);
	--font-size: var(--label-typography-size);
	--font-family: var(--label-typography-family);
	--font-weight: var(--label-typography-weight);
	--letter-spacing: var(--label-typography-letter-spacing);
	--line-height: var(--label-typography-line-height);

	align-items: center;
	background-color: var(--background, transparent);
	border:
		var(--border-width, 0)
		var(--border-style, none)
		var(--border-color, transparent);
	border-radius: var(--border-radius);
	color: var(--color);
	display: inline-flex;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: var(--spacing-3xs);
	justify-content: center;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding: var(--spacing-3xs);

	&:focus {
		text-decoration: none;
	}

	&.isFilled {
		text-decoration: none;

		&:active,
		&:focus,
		&:hover {
			text-decoration: none;
		}
	}
}

.commerce {
	--color: var(--label-color-commerce-text);

	&.isFilled {
		--background-color: var(--label-color-media-filled-background);
		--color: var(--label-color-media-filled-text);
	}
}

.media {
	--color: var(--label-color-media-text);

	&.isFilled {
		--background-color: var(--label-color-media-filled-background);
		--color: var(--label-color-media-filled-text);
	}
}

.category {
	--background-color: transparent;
	--color: var(--label-color-category-text);

	&:active {
		--color: var(--label-color-category-active-text);
	}

	&:focus {
		--border-color: var(--label-color-category-focus-border-color);
		--border-width: var(--label-color-category-focus-border-width);
		--border-style: var(--label-color-category-focus-border-style);
		--color: var(--label-color-category-focus-text);
	}

	&:hover {
		--color: var(--label-color-category-hover-text);
	}

	&.isFilled {
		--color: var(--label-color-category-filled-text);
		--background-color: var(--label-color-category-filled-background);

		&:active {
			--background-color: var(--label-color-category-filled-active-background);
			--color: var(--label-color-category-filled-active-text);
		}

		&:hover {
			--background-color: var(--label-color-category-filled-hover-background);
			--color: var(--label-color-category-filled-hover-text);
		}

		&:focus {
			--border-color: var(--label-color-category-filled-focus-border-color);
			--border-width: var(--label-color-category-filled-focus-border-width);
			--border-style: var(--label-color-category-filled-focus-border-style);
			--background-color: var(--label-color-category-filled-focus-background);
			--color: var(--label-color-category-filled-focus-text);
		}
	}
}

.sponsored {
	--color: var(--label-color-sponsored-text);

	&.isFilled {
		--color: var(--label-color-sponsored-filled-text);
		--background-color: var(--label-color-sponsored-filled-background);
	}
}
