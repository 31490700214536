.productCard {
	--background: var(--card-color-background);
	--color: var(--card-color-text);
	--font-family: var(--card-typography-m-body-family);
	--font-size: var(--card-typography-m-body-size);
	--font-weight: var(--card-typography-m-body-weight);
	--letter-spacing: var(--card-typography-m-body-letter-spacing);
	--line-height: var(--card-typography-m-body-line-height);

	background: var(--background);
	color: var(--color);
	display: flex;
	flex-direction: column;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	justify-content: left;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	@media (--bp-medium) {
		flex-direction: row;
	}
}

.productCardBadge {
	position: absolute;
	z-index: var(--z-index-low, 1);
}

.productCardImageWrapper {
	margin: 0;

	@media (--bp-medium) {
		flex: 0 0 50%;
		padding-top: var(--spacing-s);
	}
}

.productCardImageContainer {
	aspect-ratio: var(--ar-1-1);
	margin: 0 auto;
	max-width: 322px;
	position: relative;
	width: 100%;
}

.productCardImage {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.productCardInfo {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
	padding-top: var(--spacing-xs);

	@media (--bp-medium) {
		padding-left: var(--spacing-xs);
		padding-top: var(--spacing-xs);
	}

	@media (--bp-xlarge) {
		--font-family: var(--card-typography-m-body-desktop-family);
		--font-size: var(--card-typography-m-body-desktop-size);
		--font-weight: var(--card-typography-m-body-desktop-weight);
		--letter-spacing: var(--card-typography-m-body-desktop-letter-spacing);
		--line-height: var(--card-typography-m-body-desktop-line-height);
	}
}

.productCardDescription {
	margin-bottom: 0;

	@media (--bp-medium) {
		--padding-block: var(--spacing-xs);
	}
}

.productCardTitle {
	--font-family: var(--card-typography-m-title-family);
	--font-size: var(--card-typography-m-title-size);
	--font-weight: var(--card-typography-m-title-weight);
	--letter-spacing: var(--card-typography-m-title-letter-spacing);
	--line-height: var(--card-typography-m-title-line-height);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	@media (--bp-large) {
		--font-family: var(--card-typography-m-title-desktop-family);
		--font-size: var(--card-typography-m-title-desktop-size);
		--font-weight: var(--card-typography-m-title-desktop-weight);
		--letter-spacing: var(--card-typography-m-title-desktop-letter-spacing);
		--line-height: var(--card-typography-m-title-desktop-line-height);
	}
}

.productCardTitleLink {
	text-decoration: none;
}

.productCardAttribution {
	--color: var(--card-color-caption);
	--font-family: var(--card-typography-caption-family);
	--font-size: var(--card-typography-caption-size);
	--font-weight: var(--card-typography-caption-weight);
	--letter-spacing: var(--card-typography-caption-letter-spacing);
	--line-height: var(--card-typography-caption-line-height);

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	@media (--bp-large) {
		--font-weight: var(--card-typography-caption-desktop-weight);
		--letter-spacing: var(--card-typography-caption-desktop-letter-spacing);
		--line-height: var(--card-typography-caption-desktop-line-height);
		--font-size: var(--card-typography-caption-desktop-size);
		--font-family: var(--card-typography-caption-desktop-family);
	}
}

.productCardPrice {
	margin-bottom: 0;
}

.productCardButton {
	justify-content: center;
	margin-top: var(--spacing-xs);
	width: 100%;

	@media (--bp-large) {
		min-width: fit-content;
		width: 50%;
	}
}
