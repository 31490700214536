.cardWrapper {

	&:global(.alignwide) {
		--max-width-grid: 85rem;

		margin-inline: 0;
		padding-inline: 0;

		@media (--bp-medium) {
			margin-inline: var(--margin-full-width);
			padding-inline: var(--spacing-2xs);
		}
	}
}

.card {
	background-color: var(--background);
	color: var(--color);
	display: flex;
	gap: var(--spacing-2xs);
	margin: 0 auto;
	max-width: var(--max-width-grid);
	overflow: hidden;
	position: relative;

	/* list card - desktop small */
	--title-family: var(--card-typography-title-family);
	--title-size: var(--card-typography-title-size);
	--title-weight: var(--card-typography-title-weight);
	--title-letter-spacing: var(--card-typography-title-letter-spacing);
	--title-line-height: var(--card-typography-title-line-height);

	/* list card - mobile small + large */
	@container (min-width: 323px) {
		--title-family: var(--card-typography-title-l-family);
		--title-size: var(--card-typography-title-l-size);
		--title-weight: var(--card-typography-title-l-weight);
		--title-letter-spacing: var(--card-typography-title-l-letter-spacing);
		--title-line-height: var(--card-typography-title-l-line-height);
	}

	/* list card - desktop large */
	@container (min-width: 360px) {
		--title-family: var(--card-typography-title-desktop-family);
		--title-size: var(--card-typography-title-desktop-size);
		--title-weight: var(--card-typography-title-desktop-weight);
		--title-letter-spacing: var(--card-typography-title-desktop-letter-spacing);
		--title-line-height: var(--card-typography-title-desktop-line-height);
	}

	&.stacked {
		flex-direction: column;

		/* Mobile 4-up | Mobile/Headline - 2XS*/
		--title-family: var(--card-typography-title-stacked-family);
		--title-size: var(--card-typography-title-stacked-size);
		--title-weight: var(--card-typography-title-stacked-weight);
		--title-letter-spacing: var(--card-typography-title-stacked-letter-spacing);
		--title-line-height: var(--card-typography-title-stacked-line-height);

		/* Desktop 4-up Mobile 3/2/1-up | D/Headline - 2XS M/Headline - XS */
		@container (min-width: 322px) {
			--title-family: var(--card-typography-title-stacked-xs-family);
			--title-size: var(--card-typography-title-stacked-xs-size);
			--title-weight: var(--card-typography-title-stacked-xs-weight);
			--title-letter-spacing: var(--card-typography-title-stacked-xs-letter-spacing);
			--title-line-height: var(--card-typography-title-stacked-xs-line-height);
		}

		@container (min-width: 351px) {

			/* Mobile 1-up large | Mobile/Headline - M */
			&.stackedLarge {
				--title-family: var(--card-typography-title-stacked-s-family);
				--title-size: var(--card-typography-title-stacked-s-size);
				--title-weight: var(--card-typography-title-stacked-s-weight);
				--title-letter-spacing: var(--card-typography-title-stacked-s-letter-spacing);
				--title-line-height: var(--card-typography-title-stacked-s-line-height);
			}
		}

		/* Desktop 3/2-up | Desktop/Headline - XS */
		@container (min-width: 438px) {
			--title-family: var(--card-typography-title-stacked-m-family);
			--title-size: var(--card-typography-title-stacked-m-size);
			--title-weight: var(--card-typography-title-stacked-m-weight);
			--title-letter-spacing: var(--card-typography-title-stacked-m-letter-spacing);
			--title-line-height: var(--card-typography-title-stacked-m-line-height);
		}

		/* Desktop 1-up | Desktop/Headline - S */
		@container (min-width: 1012px) {
			--title-family: var(--card-typography-title-stacked-l-family);
			--title-size: var(--card-typography-title-stacked-l-size);
			--title-weight: var(--card-typography-title-stacked-l-weight);
			--title-letter-spacing: var(--card-typography-title-stacked-l-letter-spacing);
			--title-line-height: var(--card-typography-title-stacked-l-line-height);

			/* Desktop 1-up large | Desktop/Headline - M */
			&.stackedLarge {
				--title-family: var(--card-typography-title-stacked-xl-family);
				--title-size: var(--card-typography-title-stacked-xl-size);
				--title-weight: var(--card-typography-title-stacked-xl-weight);
				--title-letter-spacing: var(--card-typography-title-stacked-xl-letter-spacing);
				--title-line-height: var(--card-typography-title-stacked-xl-line-height);
			}
		}
	}

	&.fiftyFifty {
		border-radius: var(--radius-media);
		gap: unset;
		text-align: center;

		--content-padding: var(--spacing-2xl) var(--spacing-xl);
		--title-family: var(--card-typography-title-50-50-family);
		--title-size: var(--card-typography-title-50-50-size);
		--title-weight: var(--card-typography-title-50-50-weight);
		--title-letter-spacing: var(--card-typography-title-50-50-letter-spacing);
		--title-line-height: var(--card-typography-title-50-50-line-height);

		& .cardContent {
			align-items: center;
			flex: 1 1 50%;
			gap: var(--spacing-s);
			justify-content: center;
			padding: var(--content-padding);
		}

		& .cardMedia {
			flex: 1 1 50%;
		}

		& .cardMetadata {
			display: flex;
			flex-direction: column;
			row-gap: var(--spacing-4xs);
		}

		& .cardContributorContainer {
			display: unset;
		}

		&.mediaTop {

			& .cardMedia {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: var(--radius-media);
				border-top-right-radius: var(--radius-media);
			}
		}

		&.mediaBottom {

			& .cardMedia {
				border-bottom-left-radius: var(--radius-media);
				border-bottom-right-radius: var(--radius-media);
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}

		@media (--bp-medium-max) {
			flex-direction: column;

			&.mediaBottom {
				flex-direction: column-reverse;
			}

			&.mediaBottom,
			&.mediaTop {

				& .cardImage {
					width: 100%;
				}
			}
		}

		@media (--bp-medium) {
			flex-direction: row;

			--content-padding: var(--spacing-xl-desktop);
			--title-family: var(--card-typography-title-50-50-desktop-family);
			--title-size: var(--card-typography-title-50-50-desktop-size);
			--title-weight: var(--card-typography-title-50-50-desktop-weight);
			--title-letter-spacing: var(--card-typography-title-50-50-desktop-letter-spacing);
			--title-line-height: var(--card-typography-title-50-50-desktop-line-height);

			&.mediaRight {
				flex-direction: row-reverse;

				& .cardMedia {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: var(--radius-media);
					border-top-left-radius: 0;
					border-top-right-radius: var(--radius-media);
				}
			}

			&.mediaLeft {

				& .cardMedia {
					border-bottom-left-radius: var(--radius-media);
					border-bottom-right-radius: 0;
					border-top-left-radius: var(--radius-media);
					border-top-right-radius: 0;
				}
			}
		}

		@container (min-width: 1360px) {
			--title-size: var(--card-typography-title-50-50-desktop-size);
			--title-weight: var(--card-typography-title-50-50-desktop-weight);
			--title-line-height: var(--card-typography-title-50-50-desktop-line-height);
			--title-letter-spacing: var(--card-typography-title-50-50-desktop-letter-spacing);
			--title-family: var(--card-typography-title-50-50-desktop-family);
		}

		&:global(.is-style-default) {
			--background: var(--color-accent2);

			background-color: var(--background);
		}
	}

	&.list {

		& .cardMedia {
			align-self: flex-start;
			aspect-ratio: 1 / 1;
			flex: 0 0 72px;
		}
	}

	&.list.mediaRight {
		flex-direction: row-reverse;
	}
}

.cardMedia {
	--border-radius: var(--radius-media);

	border-radius: var(--border-radius);
	margin: 0;
	overflow: hidden;
	position: relative;
}

.cardImage {
	aspect-ratio: 1 / 1;
	display: block;
	height: 100%;
	object-fit: cover;
	transition: transform var(--motion-speed-short) ease-out;
	width: 100%;
}

.card:hover,
.card:focus-within {

	& .cardImage {
		transform: scale(1.05);
	}
}

.cardMedia43 {
	aspect-ratio: 4 / 3;
}

.cardMedia916 {
	aspect-ratio: 9 / 16;
}

.cardMedia169 {
	aspect-ratio: 16 / 9;
}

.cardMedia11 {
	aspect-ratio: 1 / 1;
}

.cardMedia45 {
	aspect-ratio: 4 / 5;
}

.cardContent {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-3xs);
}

.cardContentIsVideo {
	position: relative;
}

.cardCategory {
	align-content: flex-start;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-3xs);
	text-transform: uppercase;
}

.cardCategoryLabel {

	/* Required so the color comes from theme not label component */
	--color: var(--color, currentColor);

	padding: 0;
}

.cardTitleLink {
	--color: var(--color, currentColor);

	text-decoration: none;

	&::after {
		content: "";
		display: block;
		inset: 0;
		position: absolute;
	}

	&:hover,
	&:focus {
		--color: var(--color, currentColor);

		text-decoration: none;
	}
}

.cardTitle {
	font-family: var(--title-family);
	font-size: var(--title-size);
	font-weight: var(--title-weight);
	letter-spacing: var(--title-letter-spacing);
	line-height: var(--title-line-height);
}

.cardMetadata {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-4xs);
}

.cardContributor {
	--color: var(--color-neutral-dark-70);
	--font-family: var(--card-typography-contributor-name-family);
	--font-size: var(--card-typography-contributor-name-size);
	--font-weight: var(--card-typography-contributor-name-weight);
	--line-height: var(--card-typography-contributor-name-line-height);
	--letter-spacing: var(--card-typography-contributor-name-letter-spacing);

	@media (--bp-medium) {
		--font-family: var(--card-typography-contributor-name-desktop-family);
		--font-size: var(--card-typography-contributor-name-desktop-size);
		--font-weight: var(--card-typography-contributor-name-desktop-weight);
		--line-height: var(--card-typography-contributor-name-desktop-line-height);
		--letter-spacing: var(--card-typography-contributor-name-desktop-letter-spacing);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-transform: uppercase;
}

.cardDate {
	--color: var(--color-neutral-dark-70);
	--font-family: var(--card-typography-date-family);
	--font-size: var(--card-typography-date-size);
	--font-weight: var(--card-typography-date-weight);
	--line-height: var(--card-typography-date-line-height);
	--letter-spacing: var(--card-typography-date-letter-spacing);

	@media (--bp-medium) {
		--font-family: var(--card-typography-date-desktop-family);
		--font-size: var(--card-typography-date-desktop-size);
		--font-weight: var(--card-typography-date-desktop-weight);
		--line-height: var(--card-typography-date-desktop-line-height);
		--letter-spacing: var(--card-typography-date-desktop-letter-spacing);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.cardButton {
	flex-shrink: 0;
	position: relative;
	z-index: 1;
}


@container style(--is-dark-mode: 1) {

	.cardContributor,
	.cardDate {
		--color: var(--color-neutral-light-70);
	}
}
