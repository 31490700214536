.carousel {
	--font-family: var(--typography-body-family);
	--vertical-spacing: var(--spacing-3xl);

	@media (--bp-large) {
		--vertical-spacing: var(--spacing-3xl-desktop);
	}

	color: var(--color);
	display: block;
	font-family: var(--font-family);
	margin-block: var(--vertical-spacing);
	margin-left: var(--margin-full-width);
	margin-right: var(--margin-full-width);
	max-width: var(--screen-width);
	padding-inline: var(--spacing-2xs);
}

.carouselContainer {
	--container-width: 85rem;

	@media (--bp-large) {
		margin: 0 auto;
		max-width: var(--container-width);
		width: 100%;
	}

	& :global(.carousel) {
		overflow: hidden;
	}

	& :global(.product-card) {
		height: 100%;

		@media (--bp-medium) {
			flex-direction: column;
			justify-content: space-evenly;
		}
	}

	& :global(.product-card__badge) {
		--font-size: var(--typography-body-s-desktop-size);
		font-size: var(--font-size);
	}

	& :global(.product-card__info) {
		height: 100%;
		justify-content: space-between;
	}

	& :global(.product-card__title) {

		@media (--bp-large) {
			--font-family: var(--card-typography-m-title-family);
			--font-size: var(--card-typography-m-title-size);
			--font-weight: var(--card-typography-m-title-weight);
			--letter-spacing: var(--card-typography-m-title-letter-spacing);
			--line-height: var(--card-typography-m-title-line-height);
		}
	}

	& :global(.product-card__image_container) {
		width: 100%;
	}
}

.carouselHeader {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-2xs);
	margin-bottom: var(--spacing-s);

	@media (--bp-medium) {
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}
}

.carouselContent {
	max-width: 48rem;
}

.carouselTitle {
	--font-family: var(--carousel-typography-title-family);
	--font-size: var(--carousel-typography-title-size);
	--font-weight: var(--carousel-typography-title-weight);
	--letter-spacing: var(--carousel-typography-title-letter-spacing);
	--line-height: var(--carousel-typography-title-line-height);

	@media (--bp-large) {
		--font-family: var(--carousel-typography-title-desktop-family);
		--font-size: var(--carousel-typography-title-desktop-size);
		--font-weight: var(--carousel-typography-title-desktop-weight);
		--letter-spacing: var(--carousel-typography-title-desktop-letter-spacing);
		--line-height: var(--carousel-typography-title-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.carouselSubtitle {
	--font-family: var(--carousel-typography-subhead-family);
	--font-size: var(--carousel-typography-subhead-size);
	--font-weight: var(--carousel-typography-subhead-weight);
	--letter-spacing: var(--carousel-typography-subhead-letter-spacing);
	--line-height: var(--carousel-typography-subhead-line-height);

	@media (--bp-large) {
		--font-family: var(--carousel-typography-subhead-desktop-family);
		--font-size: var(--carousel-typography-subhead-desktop-size);
		--font-weight: var(--carousel-typography-subhead-desktop-weight);
		--letter-spacing: var(--carousel-typography-subhead-desktop-letter-spacing);
		--line-height: var(--carousel-typography-subhead-desktop-line-height);
	}

	color: inherit;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.carouselControlsWrap {
	align-items: center;
	column-gap: var(--spacing-l);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	row-gap: var(--spacing-m);
}

.carouselPagination {
	--font-family: var(--carousel-typography-pagination-family);
	--font-size: var(--carousel-typography-pagination-size);
	--font-weight: var(--carousel-typography-pagination-weight);
	--letter-spacing: var(--carousel-typography-pagination-letter-spacing);
	--line-height: var(--carousel-typography-pagination-line-height);

	@media (--bp-large) {
		--font-family: var(--carousel-typography-pagination-desktop-family);
		--font-size: var(--carousel-typography-pagination-desktop-size);
		--font-weight: var(--carousel-typography-pagination-desktop-weight);
		--letter-spacing: var(--carousel-typography-pagination-desktop-letter-spacing);
		--line-height: var(--carousel-typography-pagination-desktop-line-height);
	}

	align-items: center;
	color: inherit;
	display: flex;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.carouselControls {
	display: flex;
	flex-direction: row-reverse;
	gap: var(--spacing-l);
	justify-content: space-between;

	@media (--bp-medium) {
		flex-direction: row;
	}
}

.carouselControls,
.carouselPagination {
	transition: opacity 0.3s;
}

.carouselButtons {
	align-items: center;
	display: grid;
	gap: var(--spacing-2xs);
	grid-template-columns: repeat(2, 1fr);
}

.carouselNoControls :is(.carouselControls, .carouselPagination) {
	opacity: 0;
	pointer-events: none;
}

.carouselButton {

	& svg {
		height: 15px;
		width: 15px;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.carouselControlsCta {
	width: auto;
}

/* Carousel: Category Variant */
:global(.carousel-category) {

	& .carouselHeader {
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}

	& .carouselPagination {
		display: none;
	}
}

@container style(--is-dark-mode: 1) {

	.carousel,
	.carouselTitle {
		--color: var(--color-neutral-white);
	}
}
