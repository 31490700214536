.tableOfContents {
	--background-color: transparent;
	--border-color: var(--color-neutral-dark-10);
	--border-radius: var(--radius-m);
	--border-width: 1px;
	--padding-bottom: var(--spacing-l);
	--vertical-margin: var(--spacing-3xl);

	@media (--bp-medium) {
		--padding-bottom: var(--spacing-l-desktop);
	}

	background-color: var(--background-color);
	border: var(--border-width) solid var(--border-color);
	border-radius: var(--border-radius);
	margin-block: var(--vertical-margin);
	padding-bottom: var(--padding-bottom);
	width: 100%;
}

.tableOfContentsBackground {
	--background-color: var(--color-accent2);

	border: none;
}

.tableOfContentsTitle {
	--color: var(--color-theme-text);
	--font-size: var(--typography-h-xs-size);
	--font-weight: var(--typography-h-xs-weight);
	--line-height: var(--typography-h-xs-line-height);
	--padding: var(--spacing-l) var(--spacing-s) 0 var(--spacing-s);

	@media (--bp-medium) {
		--font-size: var(--typography-h-xs-desktop-size);
		--font-weight: var(--typography-h-xs-desktop-weight);
		--line-height: var(--typography-h-xs-desktop-line-height);
		--padding: var(--spacing-l-desktop) var(--spacing-s-desktop) 0 var(--spacing-s-desktop);
	}

	color: var(--color);
	font-family: var(--font-size);
	font-weight: var(--font-weight);
	margin-bottom: var(--spacing-xs);
	padding: var(--padding);
}

.tableOfContentsList {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
}

.tableOfContentsListItem {
	--color: var(--color-theme-text);
	--padding: var(--spacing-s);

	@media (--bp-medium) {
		--padding: var(--spacing-s-desktop);
	}

	border-bottom: var(--border-width) solid var(--border-color);
	color: var(--color);
	margin-top: 0 !important;
}

.tableOfContentsLink {
	--background-color: transparent;
	--font-family: var(--typography-body-s-family);
	--font-size: var(--typography-body-s-size);
	--font-weight: var(--typography-body-s-weight);
	--letter-spacing: var(--typography-body-s-letter-spacing);
	--line-height: var(--typography-body-s-line-height);

	align-items: center;
	background-color: var(--background-color);
	color: inherit;
	column-gap: var(--spacing-s);
	display: flex;
	justify-content: space-between;
	padding: var(--padding);
	transition: background-color var(--transition-speed-fast) var(--transition-easing-in-out);

	&:hover {
		--background-color: var(--color-neutral-dark-10);

		text-decoration: none;
	}

	@media (--bp-medium) {

		&:hover {
			text-decoration: none;

			& .tableOfContentsItemNumber {
				text-decoration: none;
			}

			& .tableOfContentsItemLabel {
				text-decoration: underline;
			}
		}
	}
}

.tableOfContentsItemLabel {
	overflow: hidden;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tableOfContentsItemNumber {
	--color: var(--color-neutral-dark-40);
	--font-size: var(--typography-h-xs-desktop-size);
	--font-weight: var(--typography-h-xs-desktop-weight);

	color: var(--color);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	text-decoration: none;
}

@container style(--is-dark-mode: 1) {

	.tableOfContents {
		--border-color: var(--color-neutral-light-10);
	}

	.tableOfContentsTitle,
	.tableOfContentsListItem {
		--color: var(--color-neutral-white);
	}

	.tableOfContentsLink {

		&:hover {
			--background-color: var(--color-neutral-light-10);
		}
	}

	.tableOfContentsItemNumber {
		--color: var(--color-neutral-light-40);
	}
}
