.wrapper {

	& :global(.wp-block-embed__wrapper) {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	& :global(.twitter-tweet) {
		margin-left: auto;
		margin-right: auto;
	}

	& :global(.wp-element-caption) {
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
	}
}
