.swipeGallery {
	text-align: center;

	& .swipeGalleryZoomButton {
		--lh-offset: 2px;

		align-items: center;
		display: flex;
		margin-top: -6px;
		padding: calc(var(--spacing-4xs-desktop) + var(--lh-offset)) var(--spacing-s) calc(var(--spacing-4xs-desktop) + var(--lh-offset));

		@media (--bp-large) {
			display: none !important;
		}

		& svg {
			flex-shrink: 0;
			height: 12px;
			width: 12px;
		}
	}
}

.swipeGalleryModal {
	align-items: center;
	backdrop-filter: blur(2px);
	background-color: rgb(from var(--color-neutral-white) r g b / 75%);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 1;
	position: fixed;
	text-align: left;
	top: 0;
	transform: scale(1);
	transition: visibility 0.01s, opacity var(--transition-speed-normal), transform var(--transition-speed-normal) ease-in-out;
	width: 100%;
	z-index: 150;

	@media (--bp-large) {
		padding: var(--gutter) var(--spacing-2xl);
	}

	&[aria-hidden="true"] {
		opacity: 0;
		transform: scale(0.9);
		transition: visibility 0.3s, opacity var(--transition-speed-normal), transform var(--transition-speed-normal) ease-in-out;
		visibility: hidden;
	}
}

.swipeGalleryContent {
	align-items: center;
	background-color: var(--color-dark-background);
	display: flex;
	height: 100%;
	position: relative;
	width: 100%;

	@media (--bp-large) {
		background-color: transparent;
		box-shadow: 0 0 20px 0 var(--color-neutral-dark-20);
		display: block;
		position: relative;
		width: 100%;
	}
}

.swipeGalleryLogo {
	color: var(--color-neutral-white);
	left: var(--spacing-m);
	position: absolute;
	top: var(--spacing-m);
	z-index: 50;

	@media (--bp-large) {
		left: var(--spacing-xl);
		top: var(--spacing-xl);
	}
}

.swipeGalleryNavButton {
	background-color: transparent;
	border: none;
	color: var(--color-neutral-white);
	cursor: pointer;
	line-height: 1;
	padding: var(--spacing-2xs);
	position: absolute;
	transition: color var(--transition-speed-fast);

	&:hover,
	&:focus {
		color: var(--color-theme-link-hover);
	}

	& svg {
		height: 32px;
		width: 32px;
	}
}

.swipeGalleryClose {
	background-color: transparent;
	border: none;
	color: var(--color-neutral-white);
	cursor: pointer;
	line-height: 1;
	padding: var(--spacing-2xs);
	position: absolute;
	right: var(--spacing-2xs);
	top: var(--spacing-s);
	transition: color var(--transition-speed-fast);
	z-index: 50;

	@media (--bp-large) {
		right: var(--spacing-l);
		top: 35px;
	}

	&:hover,
	&:focus {
		color: var(--color-theme-link-hover);
	}

	& svg path {
		filter: drop-shadow(0 0 1px rgb(0 0 0 / 85%));
	}
}

.slideGalleryNav {
	display: none;

	@media (--bp-large) {
		display: block;
		left: 0;
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
}

.swipeGalleryNextButton,
.swipeGalleryPrevButton {

	& svg {
		height: 13px;
		width: 13px;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.swipeGalleryNextButton {
	color: var(--color-neutral-white);
	left: 80px;
	position: absolute;
}

.swipeGalleryPrevButton {
	color: var(--color-neutral-white);
	position: absolute;
	right: 80px;
}

.swipeGalleryCarousel,
.swipeGalleryCarouselViewport {
	height: 100%;
	width: 100%;
}

.swipeGalleryCarousel {
	--slide-spacing: 0;
	--slide-size: 100%;

	overflow: hidden;
}

.swipeGalleryImages {
	display: flex;
	height: 100%;
	pointer-events: none;
	width: 100%;

	&::-webkit-scrollbar {
		display: none;
	}

	& button {
		pointer-events: all;
	}
}

.swipeGalleryImagesZoomed {

	& .swipeGalleryItem {
		overflow: auto;
	}
}

.swipeGalleryItemWrapper {
	flex: 100% 0 0;
	position: relative;
}

.swipeGalleryItem {
	align-items: center;
	background-color: var(--color-neutral-black);
	display: flex;
	height: 100%;
	justify-content: center;
	margin: 0;
	pointer-events: auto;
	position: relative;
	touch-action: none;
	width: 100%;
}

.swipeGalleryItemZoomed {
	touch-action: unset;
}

.swipeGalleryImage {
	display: block;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	transform-origin: left;
	transition: transform var(--transition-speed-normal);
	width: 100%;
}

.swipeGalleryImageLink {
	display: block;
	height: 100%;
	width: 100%;
}

.swipeGallerySlideContent {
	bottom: 0;
	color: var(--color-neutral-white);
	left: 0;
	padding: var(--spacing-xl) var(--spacing-xl) calc(var(--spacing-xl) * 3);
	position: absolute;
	width: 100%;

	&::after {
		background: var(--color-overlay-vertical);
		bottom: 0;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		width: 100%;
	}
}

.swipeGalleryCounter {
	align-items: center;
	bottom: 0;
	color: var(--color-neutral-white);
	display: flex;
	font-family: var(--carousel-typography-title-family);
	font-size: var(--carousel-typography-title-size);
	font-weight: var(--carousel-typography-title-desktop-weight);
	justify-content: space-between;
	left: 0;
	line-height: var(--carousel-typography-title-desktop-line-height);
	padding: var(--spacing-xl);
	position: absolute;
	width: 100%;
	z-index: 1;

	@media (--bp-large) {
		font-size: var(--carousel-typography-title-desktop-size);
	}
}

.swipeGalleryCaption {
	color: var(--color-neutral-white);
	font-size: var(--typography-h-m-size);
	line-height: var(--typography-h-m-line-height);
	margin-bottom: var(--spacing-2xs);
	max-width: 500px;
	pointer-events: auto;
	position: relative;
	z-index: 1;

	@media (--bp-large) {
		font-size: var(--typography-h-m-desktop-size);
		line-height: var(--typography-h-m-desktop-line-height);
		margin-bottom: var(--spacing-l);
	}
}

.swipeGalleryAuthor {
	position: relative;
	z-index: 1;
}
