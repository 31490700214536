.videoEmbed :global(.wp-block-embed__wrapper::before) {
	content: "";
	display: block;
	padding-top: 56.25%;
}

:global(.wp-embed-aspect-16-9) {

	&.videoEmbed :global(.wp-block-embed__wrapper::before) {
		padding-top: 56.25%;
	}
}

:global(.wp-embed-aspect-9-16) {

	&.videoEmbed {
		margin-inline: auto;
		max-width: 420px;
	}

	&.videoEmbed :global(.wp-block-embed__wrapper::before) {
		padding-top: 177.77%;
	}
}

.videoEmbed {

	& iframe {
		border: 0;
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
}
