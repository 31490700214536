.figureContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	&:global(.wp-block-image:not(.alignfull)) {
		border-radius: var(--radius-media);
	}

	&:global(.wp-block-image.alignfull) {
		clear: both;
	}
}

.figureContainer figcaption {
	margin-block: 0;
}
