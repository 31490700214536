.searchPage {
	background-color: var(--color-theme-background);
}

.noResultsPage {
	align-items: center;
	display: flex;

	& :global(.content) {
		margin-block: 0;
		padding-block: 0;
	}
}

.searchTitle {
	font-size: var(--typography-h-2xs-size);
	font-weight: var(--typography-h-2xs-weight);
	overflow: hidden;

	& span {
		display: block;
		font-size: var(--typography-h-xl-desktop-size);
		margin-left: 0.75em;
		margin-top: var(--spacing-l);
		padding: 0 0.75em;
		position: relative;
		text-indent: -0.75em;
	}
}

.searchTitle,
.noResults {
	font-family: var(--typography-h-xl-desktop-family);
	font-weight: var(--typography-h-xl-weight);
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
}

.noResults {
	font-size: var(--typography-h-xl-desktop-size);
}

.searchGrid {
	--grid-gutter: var(--spacing-xl);
}
